.popupRecap {
    margin: 8px;
    padding: 8px;    
    border-radius: 4px;
    display:grid;
    grid-template-columns: max-content max-content;
    grid-gap:10%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.title {
    padding: 2px;  
    color: #008951;
}


.popupRecap table       { 
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
.tableRecap tr       { 
    text-align:left;
    padding-right: 50px;
}

.tableRecap th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #008951;
    color: white;
}
.tableRecap tr:nth-child(even){background-color: #f2f2f2;}
.tableRecap tr:hover {background-color: #ddd;}

.tableRecap td, .popupRecap th      { 
    text-align:left;
    padding-right: 50px;
    padding-bottom: 10px;
    border: #ddd;
    padding: 8px;
}
.buttonDownload {
    display: inline-block !important;
	position: relative !important;
	padding: 10px 25px !important;
	background-color: white !important;
	color: #1976d2 !important;
	font-family: sans-serif !important;
	text-decoration: none !important;
	font-size: 0.9em !important;
	text-align: center;
	text-indent: 15px;
}

.buttonDownload:hover {
	background-color: #333;
	color: #1976d2;
}

.buttonDownload:before, .buttonDownload:after {
	content: ' ';
	display: block;
	position: absolute;
	left: 15px;
	top: 52%;
}

/* Download box shape  */
.buttonDownload:before {
	width: 10px;
	height: 2px;
	border-style: solid;
	border-width: 0 2px 2px;
}

/* Download arrow shape */
.buttonDownload:after {
	width: 0;
	height: 0;
    margin-left: 1px;
	margin-top: -7px;
	border-style: solid;
	border-width: 4px 4px 0 4px;
	border-color: transparent;
	border-top-color: inherit;
	
	animation: downloadArrow 2s linear infinite;
	animation-play-state: paused;
}

.buttonDownload:hover:before {
	border-color: #1976d2;
}

.buttonDownload:hover:after {
	border-top-color: #1976d2;
	animation-play-state: running;
}

/* keyframes for the download icon anim */
@keyframes downloadArrow {
	/* 0% and 0.001% keyframes used as a hackish way of having the button frozen on a nice looking frame by default */
	0% {
		margin-top: -7px;
		opacity: 1;
	}
	
	0.001% {
		margin-top: -15px;
		opacity: 0;
	}
	
	50% {
		opacity: 1;
	}
	
	100% {
		margin-top: 0;
		opacity: 0;
	}
}