.ag-header {
    /* use theme parameters where possible */
    --ag-foreground-color: #008951;
}
.ag-header-cell-label {
    justify-content: center;
 }
 .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .box select {
    background-color: white;
    color:  #0563af;
    padding: 12px;
    width: 200px;
    border: none;
    font-size: 14px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
  }
  
  .box::before {
    font-family: FontAwesome;
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 45px;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    pointer-events: none;
  }
  
  .box:hover::before {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .box select option {
    padding: 30px;
  }

  /*Button choisir*/
.choose {
  width: 100%;
  height: 80%;
  color: #fff;
  background-color: #1976d2;
  position: relative;
  border: none;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
}

.choose:hover {
  background: #fff;
  box-shadow: 0px 2px 10px 5px #97b1bf;
  color: #000;
}

.choose:after {
  content: "";
  background: #1976d2;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.choose:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
