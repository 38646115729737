fieldset {
    margin: 8px;
    border: 1px solid silver;
    padding: 8px;    
    border-radius: 4px;
    display:grid;
    grid-template-columns: max-content max-content;
    grid-gap:10%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

legend {
    padding: 2px;  
    color: #008951;
}


fieldset table       { 
    text-align:right;     
    font-size: 0.875rem;
    margin: auto;
}
fieldset tr       { 
    text-align:left;
    padding-right: 50px;
}
fieldset.settings th {
    color: #C1533D
}
fieldset td, fieldset th      { 
    text-align:left;
    padding-right: 50px;
    padding-bottom: 10px;
}
fieldset.info, fieldset.contact {
    display: block;
    text-align: center;
    position: relative;
    width: 450px;
}
fieldset.contact,fieldset.contact table{
    margin-bottom: auto;
    top: 15%;
    position: relative;
 }
 fieldset.contact td{
    width:50%;
 }
 fieldset.contact tbody tr td:first-child{
    text-align:right;
    padding-right:20px;
 }
 fieldset.command table {
    position: relative;
    margin: auto;
    border-collapse: separate;
    border-spacing: 45px 10px;
}
 fieldset.command td {
    font-weight: 500;
    font-style: oblique;
    width: 150px;
    text-align: center;
    padding: 5px;
 }
 fieldset.command th {
    font-weight: 400;
    width: 150px;
    text-align: center;
    padding: 5px;
    color: #C1533D
}